import { getLocationFromIPAddress } from "apis/external/IpInfo";
import { useEffect, useState } from "react";
import type { Nullable, Optional } from "types/utils";
import type { Address, Coordinates } from "../types/geo";
import { arrayRemoveFirstStrict } from "./arrays";
import { getSavedUserCoordinates, setSavedUserCoordinates } from "storage/localStorageAccessors";

export const metersToMiles = (distanceInMeters: number): number => {
  return distanceInMeters * 0.000621371;
};

export const displayAddressImprecise = (address: Optional<Address>): Nullable<string> => {
  if (!address) return null;
  const strings: Optional<string>[] = [];
  strings.push(address.postcode);
  strings.push(address.city);
  strings.push(address.country);
  const stringsFiltered = strings.filter((x) => !!x);
  if (stringsFiltered.length) return stringsFiltered.join(", ");
  else return null;
};

let cachedUserCoordinates: Coordinates | null = null;
let hasAttemptedToGetCoordinates = false;

type LocationCallback = (coords: Coordinates) => void;
const userLocationChangeCallbacks: LocationCallback[] = [];

const setCachedUserCoordinates = (coords: Coordinates) => {
  cachedUserCoordinates = coords;
  userLocationChangeCallbacks.forEach((callback) => callback(coords));
};

const listenForUserCoordinates = async (newListener: LocationCallback) => {
  userLocationChangeCallbacks.push(newListener);
  if (hasAttemptedToGetCoordinates) {
    if (cachedUserCoordinates) newListener(cachedUserCoordinates);
    return;
  }

  const successCallback = (pos: Coordinates) => {
    setSavedUserCoordinates(pos);
    setCachedUserCoordinates(pos);
  };

  const fallbackCoordinatesGetter = async () => {
    const fallbackCoords = await getLocationFromIPAddress();
    if (fallbackCoords) successCallback(fallbackCoords);
  };

  // While we try and fetch their new location, use the last one we've fetched
  const lastKnownLocation = getSavedUserCoordinates();
  if (lastKnownLocation) {
    successCallback(lastKnownLocation);
  }

  hasAttemptedToGetCoordinates = true;

  if (navigator.geolocation) {
    const permissions = await navigator.permissions.query({ name: "geolocation" });
    if (permissions.state === "granted" || permissions.state === "prompt") {
      navigator.geolocation.getCurrentPosition((pos) => {
        successCallback({
          latitude: pos.coords.latitude,
          longitude: pos.coords.longitude,
        });
      }, fallbackCoordinatesGetter);
    } else if (permissions.state === "denied") {
      fallbackCoordinatesGetter();
    }
  } else {
    console.warn("listenForUserCoordinates failed"); //TODO: I should use sentry or something
  }
};

export const useUserCoordinates = () => {
  const [coordinates, setCoordinates] = useState<Nullable<Coordinates>>(null);

  useEffect(() => {
    listenForUserCoordinates(setCoordinates);
    return () => {
      arrayRemoveFirstStrict(userLocationChangeCallbacks, (x) => x === setCoordinates);
    };
  }, []);

  return coordinates;
};

export const manuallySetUserCoordinates = (coordinates: Coordinates) => {
  setCachedUserCoordinates(coordinates);
};
