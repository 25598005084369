import { ActionIcon, Group, Space, Text } from "@mantine/core";
import { getCurrentGeoMaster } from "geomasters/geomasterGetter";
import { useCallback, useEffect, useState } from "react";
import { FiCrosshair, FiEdit } from "react-icons/fi";
import { displayAddressImprecise, manuallySetUserCoordinates, useUserCoordinates } from "utils/geo";
import { useBooleanState } from "utils/hooks";
import { Modal, Title } from "@mantine/core";
import type { GeographicPlace } from "types/geo";
import PlacesAutocompleteText from "./PlaceAutocompleteTextInput";

interface UserCoordinatesDisplayerProps {
  allowEdit?: boolean;
}

export const UserCoordinatesDisplayer = (props: UserCoordinatesDisplayerProps) => {
  const userLocation = useUserCoordinates();
  const [text, setText] = useState<string>("No user location");
  const [isModalOpen, openModal, closeModal] = useBooleanState(false);

  useEffect(() => {
    if (!userLocation) return;
    setText("Getting location name...");
    getCurrentGeoMaster()
      .getPlaceFromSpecificCoordinates(userLocation)
      .then((location) => {
        const addressString = displayAddressImprecise(location.address);
        setText(addressString ?? "Location with no name");
      });
  }, [userLocation, setText]);

  return (
    <Group className="gap-1" c="dimmed">
      <UserCoordinatesModal isOpen={isModalOpen} onClose={closeModal} />
      <Text size={"sm"} display={"flex"}>
        <FiCrosshair />
      </Text>
      <div className="flex-1">
        <Text size={"sm"}>{text}</Text>
      </div>
      {props.allowEdit && (
        <ActionIcon onClick={openModal} variant="transparent">
          <FiEdit />
        </ActionIcon>
      )}
    </Group>
  );
};

interface UserCoordinatesModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const UserCoordinatesModal = (props: UserCoordinatesModalProps) => {
  const { isOpen, onClose } = props;

  const onLocation = useCallback((location: GeographicPlace) => {
    manuallySetUserCoordinates(location.coords);
  }, []);

  return (
    <Modal.Root opened={isOpen} onClose={onClose}>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Title order={3}>Location Changer</Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <Text>
            <strong>Checking out a city you're not physically in?</strong>
            <br />
            Enter the city below to better guide our place search and autocomplete.
          </Text>
          <Space h="sm" />
          <UserCoordinatesDisplayer />
          <Space h="sm" />
          <PlacesAutocompleteText onLocationChosen={onLocation} />
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
