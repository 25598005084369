import { Button, Space, Text, Textarea, Title } from "@mantine/core";
import axios from "axios";
import Color from "color";
import { type ChangeEvent, useCallback, useEffect, useState } from "react";
import { Panel, PanelGroup } from "react-resizable-panels";
import { h3SetToFeatureCollection } from "geojson2h3";
import { StandardPanelResizeHandle } from "common_ui/StandardPanelResizeHandle";
import type { FeatureCollection, Feature as FeatureType, MultiPolygon, Polygon } from "geojson";
import Feature, { type FeatureLike } from "ol/Feature";
import { Point } from "ol/geom";
import { fromLonLat } from "ol/proj";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import Style from "ol/style/Style";
import { makePage } from "page_setup/makePage";
import type { Undefined } from "types/utils";
import { range } from "utils/arrays";
import { getEnvVariable } from "utils/env";
import { clamp } from "utils/numbers";
import {
  COMMON_LAT_LANG_COORDINATE_PROJECTION,
  OPEN_LAYERS_DEFAULT_MAP_PROJECTION,
} from "utils/openLayers/OpenLayersConstants";
import { defaultGeojsonObject } from "./data/defaultPolygons";
import { useDevMap } from "./DevMap";
import { PersistentMapAnchor } from "common_ui/openlayers_specific/PersistentMapAnchor";
import { ButtonPanel } from "common_ui/ButtonPanel";

const ISOCHRONE_BUCKETS = 3;
const ISOCHRONE_TIME_MS = 20 * 60 * 1000; //20 minutes
const DEFAULT_POINT = [-73.9964, 40.7057];

const styleFunction = (feature: FeatureLike) => {
  const min = Color("green");
  const max = Color("red");
  let color = Color("blue");

  let bucketNumber = 0;

  //For Graphhopper
  const explicitBucketNumber = parseInt(feature.getProperties()["bucket"]);
  if (!isNaN(explicitBucketNumber)) {
    bucketNumber = explicitBucketNumber;
    color = min.mix(max, clamp(bucketNumber / ISOCHRONE_BUCKETS, 0, 1));
  }

  //For Targomo Isochrones
  let explicitTime = parseInt(feature.getProperties()["time"]);
  if (!isNaN(explicitTime)) {
    const incrementInSeconds = ISOCHRONE_TIME_MS / ISOCHRONE_BUCKETS / 1000;
    bucketNumber = Math.floor(explicitTime / incrementInSeconds);
    color = min.mix(max, clamp(bucketNumber / ISOCHRONE_BUCKETS, 0, 1));
  }

  //For Targomo Multigraphs
  explicitTime = parseInt(feature.getProperties()["weight"]);
  if (!isNaN(explicitTime)) {
    color = min.mix(max, clamp(explicitTime / (ISOCHRONE_TIME_MS / 1000), 0, 1));
  }

  return [
    new Style({
      fill: new Fill({
        color: color.alpha(0.9).string(),
      }),
      zIndex: ISOCHRONE_BUCKETS - bucketNumber,
    }),
  ];
};

const IsochroneTesterImpl = () => {
  const [textAreaInput, setTextAreaInput] = useState(defaultGeojsonObject);
  const [numberOfPolygons, setNumberOfPolygons] = useState(0);

  const {
    addPolygons,
    clearPolygons,
    clearPoints,
    getDevMapPoints,
    pointSourceRef,
    polygonSourceRef,
    mapRef,
  } = useDevMap({
    styleFunction,
  });

  // Add some default point
  useEffect(() => {
    if (!pointSourceRef.current) return;
    pointSourceRef.current.addFeature(
      new Feature({
        geometry: new Point(fromLonLat(DEFAULT_POINT)),
      })
    );
  }, [pointSourceRef]);

  const onTextAreaInputChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      setTextAreaInput(event.target.value);
    },
    [setTextAreaInput]
  );

  const onChangePolygonManually = useCallback(() => {
    addPolygons(JSON.parse(textAreaInput));
    setNumberOfPolygons(polygonSourceRef.current?.getFeatures().length ?? 0);
  }, [addPolygons, textAreaInput, polygonSourceRef]);

  const tryGraphhopperAPI = useCallback(
    async (url: string, profile: string) => {
      // Remote: https://docs.graphhopper.com/#operation/getIsochrone
      // Local: https://github.com/graphhopper/graphhopper/blob/master/docs/web/api-doc.md#isochrone
      // Known issues: https://discuss.graphhopper.com/t/struggling-with-public-transport-isochrones-on-local-install/4554
      const ghInstance = axios.create({
        baseURL: url,
        timeout: 25000,
      });

      const promises = getDevMapPoints().map(async (point) => {
        return await ghInstance.get("/isochrone", {
          params: {
            point: `${point[1]},${point[0]}`,
            time_limit: ISOCHRONE_TIME_MS / 1000,
            profile,
            reverse_flow: true,
            buckets: ISOCHRONE_BUCKETS,
            key: getEnvVariable("REACT_APP_GRAPHHOPPER_TESTING_API_KEY"),
            "pt.earliest_departure_time": new Date().toISOString(),
          },
        });
      });

      const responses = await Promise.all(promises || []);
      responses.forEach((r) => {
        const polygons = r.data.polygons as Undefined<FeatureType<Polygon>[]>;
        if (polygons) addPolygons(polygons, COMMON_LAT_LANG_COORDINATE_PROJECTION);
        setNumberOfPolygons(polygonSourceRef.current?.getFeatures().length ?? 0);
      });
    },
    [addPolygons, getDevMapPoints, polygonSourceRef]
  );

  const tryValhallaApi = useCallback(
    async (travelMode: string) => {
      // https://valhalla.github.io/valhalla/api/isochrone/api-reference/
      const vInstance = axios.create({
        baseURL: "https://valhalla1.openstreetmap.de/",
        timeout: 25000,
      });

      const promises = getDevMapPoints().map(async (point) => {
        return await vInstance.get("/isochrone", {
          params: {
            json: JSON.stringify({
              costing: travelMode,
              polygons: true,
              reverse: true,
              locations: [{ lat: point[1], lon: point[0] }],
              denoise: 0.3,
              contours: [
                {
                  time: ISOCHRONE_TIME_MS / 60000,
                },
              ],
            }),
          },
        });
      });

      const responses = await Promise.all(promises || []);
      responses.forEach((r) => {
        const polygons = r.data as Undefined<FeatureCollection<Polygon>>;
        if (polygons) addPolygons(polygons, COMMON_LAT_LANG_COORDINATE_PROJECTION);
        setNumberOfPolygons(polygonSourceRef.current?.getFeatures().length ?? 0);
      });
    },
    [addPolygons, getDevMapPoints, polygonSourceRef]
  );

  const tryTargomoIsochroneApi = useCallback(
    async (transitOptions: unknown) => {
      const buckets: number[] = [];
      for (const bucket of range(ISOCHRONE_BUCKETS)) {
        const multiple = bucket + 1;
        buckets.push((ISOCHRONE_TIME_MS / 1000 / ISOCHRONE_BUCKETS) * multiple);
      }

      // https://docs.targomo.com/#tag/Isochrone-API
      const vInstance = axios.create({
        baseURL: "https://api.targomo.com/northamerica/",
        timeout: 25000,
      });

      const r = await vInstance.post(
        "/v1/polygon",
        {
          polygon: {
            values: buckets,
            intersectionMode: "union",
            serializer: "geojson",
          },
          edgeWeight: "time",
          reverse: true,
          sources: getDevMapPoints().map((point, indx) => ({
            lat: point[1],
            lng: point[0],
            id: indx,
            tm: transitOptions,
          })),
        },
        {
          params: {
            key: getEnvVariable("REACT_APP_TARGOMO_TESTING_API_KEY"),
          },
        }
      );

      const polygons = r.data.data as Undefined<FeatureCollection<MultiPolygon>>;
      if (polygons) addPolygons(polygons, OPEN_LAYERS_DEFAULT_MAP_PROJECTION);
      setNumberOfPolygons(polygonSourceRef.current?.getFeatures().length ?? 0);
    },
    [addPolygons, getDevMapPoints, polygonSourceRef]
  );

  const tryTargomoMultigraphApi = useCallback(
    async (transitOptions: unknown) => {
      // https://docs.targomo.com/#tag/MultiGraph-API
      const vInstance = axios.create({
        baseURL: "https://api.targomo.com/northamerica/",
        timeout: 25000,
      });

      const r = await vInstance.post(
        "/v1/multigraph",
        {
          multigraph: {
            aggregation: { type: "routing_union" }, //what intersectionMode we use doesn't matter but routing_union is the cheapest
            serialization: { format: "json" },
            layer: { type: "h3hexagon", geometryDetailLevel: 10 },
          },
          edgeWeight: "time",
          maxEdgeWeight: ISOCHRONE_TIME_MS / 1000,
          reverse: true,
          sources: getDevMapPoints().map((point, indx) => ({
            lat: point[1],
            lng: point[0],
            id: indx,
            tm: transitOptions,
          })),
        },
        {
          params: {
            key: getEnvVariable("REACT_APP_TARGOMO_TESTING_API_KEY"),
          },
        }
      );

      // https://medium.com/@jesse.b.nestler/how-to-convert-h3-cell-boundaries-to-shapely-polygons-in-python-f7558add2f63
      // https://observablehq.com/@targomo/h3-with-multigraph
      const hexes = r.data.data;
      const coverage = Object.keys(hexes);
      const collection = h3SetToFeatureCollection(coverage, (d) => ({
        weight: hexes[d].w,
      }));
      addPolygons(collection);
      setNumberOfPolygons(polygonSourceRef.current?.getFeatures().length ?? 0);
    },
    [addPolygons, getDevMapPoints, polygonSourceRef]
  );

  return (
    <PanelGroup autoSaveId="devIsochroneTesterPanelGroup" direction="horizontal">
      <Panel style={{ padding: 8 }}>
        <Textarea
          label="Manual GeoJSON"
          styles={{ input: { resize: "vertical" } }}
          value={textAreaInput}
          onChange={onTextAreaInputChange}
        />
        <Space h="sm" />

        <ButtonPanel>
          <Button onClick={onChangePolygonManually}>Add Polygon Manually</Button>
          <Button onClick={clearPolygons}>Clear Polygons</Button>
          <Button onClick={clearPoints}>Clear Points</Button>
        </ButtonPanel>
        <Space h="sm" />

        <Text>Number of polygons: {numberOfPolygons}</Text>

        <Space h="sm" />
        <Title>GraphHopper</Title>
        <ButtonPanel>
          <Button onClick={() => tryGraphhopperAPI("https://graphhopper.com/api/1/", "car")}>
            Car
          </Button>
          <Button onClick={() => tryGraphhopperAPI("http://localhost:8989/", "pt")}>
            Transit (localhost)
          </Button>
        </ButtonPanel>
        <Space h="sm" />

        <Title>Valhalla</Title>
        <Text>Valhalla APIs will only use the max bucket size</Text>
        <ButtonPanel>
          <Button onClick={() => tryValhallaApi("auto")}>Car</Button>
          <Button onClick={() => tryValhallaApi("multimodal")}>Transit</Button>
        </ButtonPanel>
        <Space h="sm" />

        <Title>Targomo</Title>
        <Text>Isochrones</Text>
        <ButtonPanel>
          <Button onClick={() => tryTargomoIsochroneApi({ car: {} })}>Car</Button>
          <Button onClick={() => tryTargomoIsochroneApi({ transit: {} })}>Transit</Button>
        </ButtonPanel>

        <Text>Multigraph</Text>
        <ButtonPanel>
          <Button onClick={() => tryTargomoMultigraphApi({ car: {} })}>Car</Button>
        </ButtonPanel>
      </Panel>

      <StandardPanelResizeHandle />

      <Panel maxSize={80} minSize={20}>
        <PersistentMapAnchor className="w-100 h-full" map={mapRef.current} />
      </Panel>
    </PanelGroup>
  );
};

export const DevIsochroneTester = makePage(IsochroneTesterImpl, "<Dev Page>");
