import { mapPointerSvgBase64 } from "media/mapPointer";
import { defaults as defaultControls } from "ol/control.js";
import WebGLTileLayer, { type Style as WebGlStyle } from "ol/layer/WebGLTile";
import { transform } from "ol/proj";
import Source from "ol/source/ImageTile.js";
import Icon from "ol/style/Icon";
import Style from "ol/style/Style";
import type { ViewOptions } from "ol/View";
import type { Undefined } from "types/utils";
import {
  COMMON_LAT_LANG_COORDINATE_PROJECTION,
  OPEN_LAYERS_DEFAULT_MAP_PROJECTION,
} from "./OpenLayersConstants";
import { DEFAULT_GROUP_PIN_COLOR } from "common_ui/heatmap_specific/constants";

interface CommonTileLayerConfig {
  desaturate?: boolean;
}

/**  
 I'm not a fan of the styling of the normal https://tile.openstreetmap.org/{z}/{x}/{y}.png endpoint, and I've been trying
 to find an alternative that's not rate limited

 Other ZXY png sources I've looked into:
 https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}@2x.png (https://docs.stadiamaps.com/map-styles/osm-bright/; https://docs.stadiamaps.com/authentication/)
 https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}@2x.png
 https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x} (https://doc.arcgis.com/en/data-appliance/7.2/maps/world-street-map.htm)
 https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x} (https://doc.arcgis.com/en/data-appliance/7.2/maps/world-topographic-map.htm)
 https://basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png (can't find official docs on this cdn; https://carto.com/basemaps is the closest I think)
 https://basemaps.cartocdn.com/rastertiles/dark_all/{z}/{x}/{y}{r}.png

 Find more at https://xyzservices.readthedocs.io/en/stable/gallery.html and https://wiki.openstreetmap.org/wiki/Raster_tile_providers
 http://leaflet-extras.github.io/leaflet-providers/preview/ is also helpful

 I'd love to eventually be able to use https://openfreemap.org, but it comes with some problems
 It currently doesn't support the XYZ format: I've made a request for that at https://github.com/hyperknot/openfreemap/issues/50
 I can avoid having to use that format by using ol-mapbox-style, but the performance is poor
 I've looked into the performance issues for .pbf tiles more for openlayers, and found some interesting results

 1) https://github.com/openlayers/openlayers/issues/10883
 There's been some research in OpenLayers to utilize webworkers via OffscreenCanvas to improve performance for vector tiles
 Implemented here: https://github.com/openlayers/openlayers/pull/10828
 Example here: https://openlayers.org/en/latest/examples/offscreen-canvas.html
 The setup is complicated, though.
 I also think part of the issue is on my end - some ol-mapbox-style demos are just slower when I reproduce them in theretowhere, like this one:
 https://codesandbox.io/p/sandbox/mapbox-style-w1yor

 2) https://github.com/openlayers/openlayers/issues/7549
 This issue also talks more about the OffscreenCanvas method, but also talks about WebGLVectorTileLayerRenderer.
 Implemented here: https://github.com/openlayers/openlayers/pull/14445
 Example here: https://openlayers.org/en/latest/examples/webgl-vector-tiles.html
 It looks like its ongoing work, and I don't yet know if it even plays well with ol-mapbox-style, or
 if I can make it work without depending on their createMapboxStreetsV6Style function.
 It also doesn't seem to play well with typescript - I wonder if their examples have fallen behind their types

 Both WebGLVectorTileLayerRenderer and OffscreenCanvas are experimental and look like they'll require more deep dives to see
 if they're stable, safe, and worth the setup effort. They don't seem to be responding to people's queries about progress
 on those two fronts - not sure if that's a signal that they've been abandoned.
*/
// PRO FEATURE: Consider allowing users to choose tile layers if they pay
export const getCommonTileLayer = (config?: CommonTileLayerConfig) => {
  let style: Undefined<WebGlStyle> = undefined;
  if (config?.desaturate) {
    const variables = {
      exposure: 0,
      contrast: 0,
      saturation: -0.5,
    };
    style = {
      exposure: ["var", "exposure"],
      contrast: ["var", "contrast"],
      saturation: ["var", "saturation"],
      variables: variables,
    };
  }

  return new WebGLTileLayer({
    source: new Source({
      url: "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
      attributions:
        '<span>&#169; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> contributors. Props to them!</span>',
    }),
    style: style,
    preload: 3,
  });
};

export const getCommonControls = () => {
  return defaultControls({ attribution: true });
};

const INVISIBLE_STYLE = new Style();
export const getInvisibleStyle = () => {
  return INVISIBLE_STYLE;
};

export const MAP_TARGET_ELEMENT_ID = "theretowhere-map-element";

interface CommonPointStyleConfig {
  colorOverride?: string;
}

export const getCommonPointStyle = (config?: CommonPointStyleConfig): Style => {
  return new Style({
    image: new Icon({
      color: config?.colorOverride ?? DEFAULT_GROUP_PIN_COLOR,
      src: mapPointerSvgBase64,
      scale: 0.04,
      anchor: [0.5, 1],
      anchorXUnits: "fraction",
      anchorYUnits: "fraction",
    }),
  });
};

export const getCommonViewArgs = (): ViewOptions => {
  return {
    projection: OPEN_LAYERS_DEFAULT_MAP_PROJECTION,
    center: transform(
      [-73.9964, 40.7057],
      COMMON_LAT_LANG_COORDINATE_PROJECTION,
      OPEN_LAYERS_DEFAULT_MAP_PROJECTION
    ),
    zoom: 12,
  };
};
