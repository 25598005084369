import { Anchor, Container, Group, Image, Space, Text, Title } from "@mantine/core";
import { makePage } from "page_setup/makePage";
import { getEnvVariable } from "utils/env";
import "./BrowserExtensionPage.css";
const BrowserExtensionPageImpl = () => {
  return (
    <Container size="md" p="md">
      <Title order={2} align="center" mb="lg">
        We have an extension!
      </Title>
      <Text>
        We have an extension to allow you to view your saved heatmaps directly in listings sites:
        Supported on:
      </Text>

      <Group h={150}>
        <div className="supported-site-logo">
          <img src={require("media/apartmentscomlogo.png")} alt="Apartments.com" />
        </div>
        <div className="supported-site-logo">
          <img src={require("media/zillowcomlogo.png")} alt="Zillow" />
        </div>
        <div className="supported-site-logo">
          <img src={require("media/streeteasycomlogo.png")} alt="Streeteasy" />
        </div>
      </Group>

      <div className="p-2 flex row items-center justify-center w-100">
        <Anchor href={getEnvVariable("REACT_APP_CHROME_EXTENSION_LINK")} target="_blank">
          <img
            src={require("media/ChromeWebStoreButton.png")}
            height={60}
            alt="Extension Download Button"
          />
        </Anchor>
      </div>
      <div>
        <Image radius="md" src={require("media/WebExtensionScreenshot.jpg")} />
      </div>

      <Space h="lg" />
      <Text>
        It's only supported in Chrome right now. 🫣
        <br />
        We want to it to be supported in other browsers too! But they are missing some features that
        don't make it possible right now.
      </Text>
    </Container>
  );
};

export const BrowserExtensionPage = makePage(BrowserExtensionPageImpl, "We have an extension!", {
  hideLocationUI: true,
});
