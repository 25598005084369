import { makePage, TAB_NAME_BASE } from "page_setup/makePage";

import { Center, Container, Text } from "@mantine/core";
import { HeatmapConfigurationInner } from "pages/heatmap_configuration/HeatmapConfigurationInner";
import { useLocation } from "react-router-dom";
import type { Optional } from "types/utils";
import type { HeatmapConfigurationPageNavigationArgs } from "./types";
import type { StoredHeatmap } from "types/heatmap_storage/storedHeatmap";
import { useEffect, useState } from "react";
import { getLocallyStoredHeatmapById } from "storage/localHeatmapStorage";

const HeatmapConfigurationImpl = () => {
  const location = useLocation();
  const locationState = location.state as Optional<HeatmapConfigurationPageNavigationArgs>;
  const [heatmap, setHeatmap] = useState<StoredHeatmap>();

  useEffect(() => {
    const promise = async () => {
      const id = locationState?.heatmapId;
      if (!id) return;
      const heatmap = await getLocallyStoredHeatmapById(id);
      if (!heatmap) return;
      setHeatmap(heatmap);
    };
    promise();
  }, [location, locationState?.heatmapId]);

  if (!locationState?.heatmapId) {
    return (
      <Container>
        <Center>
          <Text>No heatmap data provided.</Text>
        </Center>
      </Container>
    );
  }

  if (!heatmap) {
    return (
      <Container>
        <Center>
          <Text>Fetching heatmap...</Text>
        </Center>
      </Container>
    );
  }

  return <HeatmapConfigurationInner heatmap={heatmap} />;
};

export const HeatmapConfiguration = makePage(
  HeatmapConfigurationImpl,
  TAB_NAME_BASE + "Heatmap Viewer"
);
