import { ActionIcon, Button, Divider, Group, Space, Text, Title, Tooltip } from "@mantine/core";
import { ErrorBoundary } from "common_ui/ErrorBoundary";
import { EmbeddedMap } from "geomasters/maps/EmbeddedMap";
import { useSmallScreen } from "page_setup/ScreenSizeContext";
import { useCallback, useEffect, useState } from "react";
import { FiMapPin, FiPlusCircle, FiSettings } from "react-icons/fi";
import { Panel, PanelGroup } from "react-resizable-panels";
import {
  getPreferredModeOfTransport,
  getSavedQueries,
  setSavedQueries,
} from "storage/localStorageAccessors";
import type { GeographicPlace } from "types/geo";
import type { Nullable, Undefined } from "types/utils";
import { useBooleanState } from "utils/hooks";
import { QueryListElement } from "./QueryListItem";
import { TransportSettingsModal } from "./TransportSettingsModal";
import { AddressSpecificQuery, OpenQuery, type Query } from "./locationQueryTypes";

import PlacesAutocompleteText from "common_ui/PlaceAutocompleteTextInput";
import { StandardEmptyState } from "common_ui/StandardEmptyState";
import { StandardPanelResizeHandle } from "common_ui/StandardPanelResizeHandle";
import { makePage, TAB_NAME_BASE } from "page_setup/makePage";
import { usePreferredModeOfTransport } from "storage/localStorageHooks";
import { TransportationModeIcon } from "./TransportationModeIcon";
import "./DistanceMatrixPage.css";
import { ButtonPanel } from "common_ui/ButtonPanel";

const DistanceMatrixPageImpl = () => {
  const [referencePlace, setReferencePlace] = useState<Nullable<GeographicPlace>>(null);
  const [queries, setQueries] = useState<Array<Query>>(getSavedQueries());
  const [isLoading, setIsLoading, setIsNotLoading] = useBooleanState(false);
  const [isSettingsModalOpen, openSettingModal, closeSettingModal] = useBooleanState(false);
  const [queryForSidepanel, setQueryForSidepanel] = useState<Undefined<Query>>();
  const [storedTransportationMode] = usePreferredModeOfTransport();
  const smallScreen = useSmallScreen();

  useEffect(() => {
    setSavedQueries(queries);
  }, [queries]);

  const addSpecificAddressQuery = useCallback(() => {
    setQueries([new AddressSpecificQuery(), ...queries]);
  }, [queries]);

  const addOpenQuery = useCallback(() => {
    setQueries([new OpenQuery(), ...queries]);
  }, [queries]);

  const requestDirections = useCallback(async () => {
    if (!referencePlace) return;
    setIsLoading();
    const promises: Promise<void>[] = [];
    for (const query of queries) {
      promises.push(query.getDirections(referencePlace));
    }
    await Promise.allSettled(promises);
    setQueries([...queries]); //For rerender
    setIsNotLoading();
  }, [queries, referencePlace, setIsLoading, setIsNotLoading]);

  const onQueryChanged = useCallback(
    (key: string) => {
      const query = queries.find((x) => x.key === key);
      if (query) query.reset();
      setQueries([...queries]); //For rerender
    },
    [queries]
  );

  const onQueryRemoved = useCallback(
    (key: string) => {
      setQueries(queries.filter((x) => x.key !== key));
      if (queryForSidepanel?.key === key) setQueryForSidepanel(undefined);
    },
    [queries, queryForSidepanel?.key]
  );

  const onQueryChosenForSidepanel = useCallback(
    (key: string) => {
      const query = queries.find((x) => x.key === key);
      setQueryForSidepanel(query);
    },
    [queries]
  );

  return (
    <ErrorBoundary>
      <TransportSettingsModal isOpen={isSettingsModalOpen} onClose={closeSettingModal} />
      <div className="main-page-container">
        <PanelGroup autoSaveId="mainPagePanelGroup" direction="horizontal">
          <Panel>
            <div className="controls-sidepanel-parent">
              <Title order={smallScreen ? 2 : 1}>Let's check travel times</Title>
              <Text>
                Type in the name or address of a place you're interested in — like an apartment,
                venue or hotel — along with the amenities or locations you want to be near. We'll
                show you how far everything is!
              </Text>
              <Space h="md" />
              <Title order={4}>What's the location you're thinking of?</Title>
              <Space h="xs" />
              <div className="row w100">
                <ErrorBoundary>
                  <PlacesAutocompleteText
                    onLocationChosen={setReferencePlace}
                    searchBarPlaceholder="Enter a place's name or address, or a listing link"
                    autoFocus
                  />
                </ErrorBoundary>
              </div>

              <Space h="md" />

              <ButtonPanel>
                <Button
                  onClick={requestDirections}
                  disabled={isLoading || !referencePlace}
                  variant="gradient"
                  gradient={{ from: "violet", to: "grape", deg: 60 }}
                >
                  What's it looking like?
                </Button>
                <ActionIcon onClick={openSettingModal} color="primary" variant="light" size="lg">
                  <FiSettings />
                </ActionIcon>

                <Group spacing={4}>
                  <Text>Using:</Text>
                  <TransportationModeIcon mode={storedTransportationMode} />
                </Group>
              </ButtonPanel>

              <Space h="md" />

              <Title order={4}>Where are you checking travel times to?</Title>
              <Space h="xs" />
              <ButtonPanel>
                <Tooltip label={"We'll check travel times to a specific place"} withArrow>
                  <Button onClick={addSpecificAddressQuery} leftIcon={<FiPlusCircle />}>
                    Specific Place
                  </Button>
                </Tooltip>

                <Tooltip
                  label={"We'll check travel times to the nearest place that matches a description"}
                  withArrow
                >
                  <Button onClick={addOpenQuery} leftIcon={<FiPlusCircle />}>
                    "Nearest" Place
                  </Button>
                </Tooltip>
              </ButtonPanel>

              <div className="query-list">
                {queries.length === 0 && (
                  <div className="w100 flex-1">
                    <StandardEmptyState
                      message="Add the locations you want to check travel times to."
                      icon={<FiMapPin />}
                    />
                  </div>
                )}

                {queries.map((option) => (
                  <ErrorBoundary key={option.key}>
                    <div className="col query-list-element">
                      <QueryListElement
                        query={option}
                        onQueryChanged={onQueryChanged}
                        onQueryRemoved={onQueryRemoved}
                        onQueryChosenForSidepanel={onQueryChosenForSidepanel}
                      />
                      <Divider className="mt-4"></Divider>
                    </div>
                  </ErrorBoundary>
                ))}
              </div>
            </div>
          </Panel>
          {!smallScreen && (
            <>
              <StandardPanelResizeHandle />
              <Panel defaultSize={25} maxSize={70} minSize={10} className="map-sidepanel">
                <EmbeddedMap
                  height={"100%"}
                  width={"100%"}
                  origin={queryForSidepanel?.route?.origin ?? referencePlace}
                  destination={queryForSidepanel?.route?.destination}
                  modeOfTransport={getPreferredModeOfTransport()}
                />
              </Panel>
            </>
          )}
        </PanelGroup>
      </div>
    </ErrorBoundary>
  );
};

export const DistanceMatrixPage = makePage(
  DistanceMatrixPageImpl,
  TAB_NAME_BASE + "Distance Matrix"
);
