import { ActionIcon, Group, Menu, useMantineTheme } from "@mantine/core";
import { FiChevronDown } from "react-icons/fi";
import "./SplitButton.css";

interface SplitButtonProps {
  button: React.ReactNode;
  options: React.ReactNode;
}

export function SplitButton(props: SplitButtonProps) {
  const theme = useMantineTheme();

  return (
    <Group style={{ flexWrap: "nowrap", gap: 0 }}>
      <div className={"split-button-button"}>{props.button}</div>
      <Menu transitionProps={{ transition: "pop" }} position="bottom-end" withinPortal>
        <Menu.Target>
          <ActionIcon
            variant="filled"
            color={theme.primaryColor}
            w={15}
            h={36}
            className={"split-button-menuControl"}
          >
            <FiChevronDown />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>{props.options}</Menu.Dropdown>
      </Menu>
    </Group>
  );
}
