import { getApp } from "firebase/app";
import {
  connectFunctionsEmulator,
  type Functions,
  getFunctions,
  httpsCallable,
} from "firebase/functions";
import { isInDevMode } from "utils/misc";
import type {
  GetListingAddressRequest,
  GetListingAddressRequestResult,
} from "types/internal_apis/get_address_from_listing_url";
import type { Heatmap, HeatmapRequest } from "types/internal_apis/get_heatmap";
import type {
  AppleMapsPlaceRequest,
  AppleMapsPlaceRequestResult,
} from "types/internal_apis/get_heatmap_places_open_search";

let functions: Functions | null = null;
const getFunctionsInstance = (): Functions => {
  if (!functions) {
    functions = getFunctions(getApp());
    if (isInDevMode()) {
      connectFunctionsEmulator(functions, "127.0.0.1", 5001);
    }
  }
  return functions;
};

const ApiGetHeatmap = async (args: HeatmapRequest) => {
  const cloudFunction = httpsCallable(getFunctionsInstance(), "get_heatmap", { timeout: 300000 });
  const result = await cloudFunction(args);
  return result.data as Heatmap;
};

const ApiGetHeatmapPlacesOpenSearch = async (args: AppleMapsPlaceRequest) => {
  const cloudFunction = httpsCallable(getFunctionsInstance(), "get_heatmap_places_open_search");
  const result = await cloudFunction(args);
  return result.data as AppleMapsPlaceRequestResult;
};

const ApiGetAddressFromListingURL = async (args: GetListingAddressRequest) => {
  const cloudFunction = httpsCallable(getFunctionsInstance(), "get_address_from_listing_url");
  const result = await cloudFunction(args);
  return result.data as GetListingAddressRequestResult;
};

export const CloudFunctions = {
  ApiGetHeatmap,
  ApiGetHeatmapPlacesOpenSearch,
  ApiGetAddressFromListingURL,
};
