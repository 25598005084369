import type { ColorScheme } from "@mantine/core";
import { getCurrentGeoMaster } from "geomasters/geomasterGetter";
import type { Query, SerializedQuery } from "pages/distance_matrix/locationQueryTypes";
import { type Coordinates, TransportationMode } from "types/geo";
import { QueryDeserializer } from "./locationQueryDeserializer";
import {
  DATA_STORAGE_KEY,
  LIGHT_OR_DARK_MODE_KEY,
  PREFERRED_MODE_OF_TRANSPORT_KEY,
  QUERIES_STORAGE_KEY,
  SEEN_ONE_TIME_TOOLTIPS_KEY,
  USER_COORDINATES_KEY,
} from "./localStorageKeys";
import type { Nullable } from "types/utils";

///////////////////////////////////
// SAVED QUERIES
///////////////////////////////////

export const getSavedQueries = (): Query[] => {
  const serializedQueriesRaw = localStorage.getItem(QUERIES_STORAGE_KEY);
  if (!serializedQueriesRaw) return [];
  const serializedQueries = JSON.parse(serializedQueriesRaw) as SerializedQuery[];
  const queries: Query[] = [];
  for (const serializedQuery of serializedQueries) {
    if (QueryDeserializer.isOpenQuery(serializedQuery))
      queries.push(QueryDeserializer.deserializeOpenQuery(serializedQuery));
    else if (QueryDeserializer.isAddressQuery(serializedQuery))
      queries.push(QueryDeserializer.deserializeAddressQuery(serializedQuery));
  }
  return queries;
};

export const setSavedQueries = (queries: Query[]) => {
  const serializedQueries = queries.map((x) => x.serialize());
  localStorage.setItem(QUERIES_STORAGE_KEY, JSON.stringify(serializedQueries));
};

///////////////////////////////////
// PREFERRED MODES OF TRANSPORT
///////////////////////////////////

export const getPreferredModeOfTransport = (): TransportationMode => {
  const defaultValue = TransportationMode.DRIVING;
  const storedValue = localStorage.getItem(PREFERRED_MODE_OF_TRANSPORT_KEY) as TransportationMode;
  if (!storedValue || !getCurrentGeoMaster().supportedModesOfTransport.includes(storedValue)) {
    return defaultValue; //TODO: tell the user about this
  } else {
    return storedValue;
  }
};

export const setPreferredModeOfTransport = (mode: TransportationMode) => {
  localStorage.setItem(PREFERRED_MODE_OF_TRANSPORT_KEY, mode);
};

///////////////////////////////////
// LIGHT OR DARK MODE
///////////////////////////////////

export const getLightOrDarkMode = (): ColorScheme => {
  const defaultValue = "light";
  const storedValue = localStorage.getItem(LIGHT_OR_DARK_MODE_KEY) as ColorScheme;
  if (!storedValue) {
    return defaultValue;
  } else {
    return storedValue;
  }
};

export const setLightOrDarkMode = (mode: ColorScheme) => {
  localStorage.setItem(LIGHT_OR_DARK_MODE_KEY, mode);
};

///////////////////////////////////
// ONE TIME TOOLTIPS
///////////////////////////////////

export const getHasSeenOneTimeTooltip = (key: string): boolean => {
  const defaultValue = false;
  const storedValue = localStorage.getItem(SEEN_ONE_TIME_TOOLTIPS_KEY);
  if (!storedValue) {
    return defaultValue;
  } else {
    const parsedValue = JSON.parse(storedValue) as string[];
    return parsedValue.includes(key);
  }
};

export const recordHasSeenOneTimeTooltip = (key: string) => {
  const storedValue = localStorage.getItem(SEEN_ONE_TIME_TOOLTIPS_KEY);
  const parsedValue = storedValue ? (JSON.parse(storedValue) as string[]) : [];
  parsedValue.push(key);
  localStorage.setItem(SEEN_ONE_TIME_TOOLTIPS_KEY, JSON.stringify(parsedValue));
};

///////////////////////////////////
// USER COORDINATES
///////////////////////////////////

export const getSavedUserCoordinates = (): Nullable<Coordinates> => {
  const userCoordinates = localStorage.getItem(USER_COORDINATES_KEY);
  if (!userCoordinates) return null;
  const userCoordinatesParsed = JSON.parse(userCoordinates) as Coordinates;
  return userCoordinatesParsed;
};

export const setSavedUserCoordinates = (coords: Coordinates) => {
  localStorage.setItem(USER_COORDINATES_KEY, JSON.stringify(coords));
};

///////////////////////////////////
// DATA STORAGE
///////////////////////////////////

export const getDataStorageKey = (): Nullable<string> => {
  const storedValue = localStorage.getItem(DATA_STORAGE_KEY);
  return storedValue;
};

export const setDataStorageKey = (key: string) => {
  localStorage.setItem(DATA_STORAGE_KEY, key);
};
