import { Avatar, Badge, Group } from "@mantine/core";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const avatar = <Avatar alt="Chrome Logo" size={24} mr={5} src={require("media/ChromeLogo.png")} />;

export function ChromeExtensionBadge() {
  const navigate = useNavigate();

  const goToBrowserExtensionPage = useCallback(() => {
    navigate("we-got-an-extension-babyyyy");
  }, [navigate]);

  return (
    <Group>
      <Badge
        pl={0}
        size="lg"
        color="violet"
        radius="xl"
        leftSection={avatar}
        onClick={goToBrowserExtensionPage}
        className="cursor-pointer"
      >
        Check out our Chrome Extension!
      </Badge>
    </Group>
  );
}
