import { Modal, Title, Text, Stack } from "@mantine/core";
import { noop } from "utils/misc";
import { LoadingIndicator } from "./LoadingIndicator";

interface LoadingModalProps {
  open: boolean;
}

export const LoadingModal = (props: LoadingModalProps) => {
  return (
    <Modal.Root centered opened={props.open} onClose={noop}>
      <Modal.Overlay blur={3} />
      <Modal.Content>
        <Modal.Header>
          <Title order={3}>Working on it...</Title>
        </Modal.Header>
        <Modal.Body>
          <Stack align="center">
            <LoadingIndicator shouldShow size={40} />
            <Text align="center">
              This could take a couple minutes.
              <br />
              Here's a little animation to keep you company :3
            </Text>
          </Stack>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
