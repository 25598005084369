import { Text } from "@mantine/core";
import { initGoogleApi } from "apis/external/GooglePlacesApi";

import { geocodeAddressFromHERE, requestAutocompleteResultsFromHERE } from "apis/external/HEREApi";
import {
  TransportationMode,
  type Coordinates,
  type GeographicPlace,
  type PartialGeographicPlace,
  type Route,
} from "types/geo";
import type { Nullable, Optional } from "types/utils";
import { GeoMaster, GeoMasterName } from "../geomaster";
import { AlfredMapIframe } from "../maps/AlfredMapIframe";
import type { EmbeddedMapProps } from "../maps/types";
import {
  getDirectionsBetweenPlacesUsingGoogle,
  hereCoordsToStandardCoords,
  reverseGeocodeToOSM,
} from "./utils";

// DESIGN:
// Relies on HERE for autocomplete, HERE for inferring places, Google for directions

/******************************************************
.___  ___.  _______  __       __       _______.     _______.     ___     
|   \/   | |   ____||  |     |  |     /       |    /       |    /   \    
|  \  /  | |  |__   |  |     |  |    |   (----`   |   (----`   /  ^  \   
|  |\/|  | |   __|  |  |     |  |     \   \        \   \      /  /_\  \  
|  |  |  | |  |____ |  `----.|  | .----)   |   .----)   |    /  _____  \ 
|__|  |__| |_______||_______||__| |_______/    |_______/    /__/     \__\

******************************************************/

export class Melissa extends GeoMaster {
  static geoMasterName = GeoMasterName.MELISSA;

  async init(): Promise<void> {
    await initGoogleApi();
  }

  async getAutocompleteFromQuery(
    query: string,
    reference?: Optional<Coordinates>
  ): Promise<PartialGeographicPlace[]> {
    const predictions = await requestAutocompleteResultsFromHERE(query, reference);
    return predictions.map((x) => ({
      geoMasterName: Melissa.geoMasterName,
      geoMasterProvidedId: x.id,
      name: x.title,
      distanceToUsersInMeters: x.distance,
      coords: hereCoordsToStandardCoords(x.position),
      subtext: x.address?.label,
    }));
  }

  async getPlaceForAutocompleteUsingQuery(
    query: string,
    reference: Optional<Coordinates>
  ): Promise<Nullable<GeographicPlace>> {
    const place = await this.getPlaceFromOpenQuery(query, reference);
    if (place) {
      const OSMLocation = await reverseGeocodeToOSM(place.coords);
      return {
        geoMasterName: Melissa.geoMasterName,
        timestampInMillis: Date.now(),
        name: OSMLocation.name ?? "Unnamed",
        coords: place.coords,
        geoMasterProvidedId: place.geoMasterProvidedId,
        address: OSMLocation.address,
      };
    } else {
      return null;
    }
  }

  async getPlaceFromSpecificCoordinates(coordinates: Coordinates): Promise<GeographicPlace> {
    const OSMLocation = await reverseGeocodeToOSM(coordinates);
    return {
      geoMasterName: Melissa.geoMasterName,
      timestampInMillis: Date.now(),
      name: OSMLocation.name ?? "Unnamed",
      coords: OSMLocation.coords,
      address: OSMLocation.address,
    };
  }

  async getPlaceForAutocompleteUsingPartial(
    partialPlace: PartialGeographicPlace
  ): Promise<GeographicPlace> {
    if (partialPlace.geoMasterName !== Melissa.geoMasterName) {
      throw new Error("Melissa received invalid data!");
    }

    if (!partialPlace.coords) {
      throw new Error("We couldn't get the coordinates of this place. Sorry!");
    }

    const OSMLocation = await reverseGeocodeToOSM(partialPlace.coords);
    return {
      geoMasterName: Melissa.geoMasterName,
      timestampInMillis: Date.now(),
      name: OSMLocation.name ?? "Unnamed",
      coords: partialPlace.coords,
      geoMasterProvidedId: partialPlace.geoMasterProvidedId,
      address: OSMLocation.address,
    };
  }

  getAttestationForAutocomplete(): React.ReactElement {
    return <Text>Powered by HERE</Text>;
  }

  async getPlaceFromOpenQuery(
    query: string,
    reference: Optional<Coordinates>
  ): Promise<Nullable<GeographicPlace>> {
    const result = await geocodeAddressFromHERE(query, reference);
    if (!result?.position) {
      throw new Error("We couldn't get the coordinates of this place. Sorry!");
    }

    return {
      geoMasterName: Melissa.geoMasterName,
      timestampInMillis: Date.now(),
      name: result.title ?? "Unnamed",
      coords: hereCoordsToStandardCoords(result.position),
      geoMasterProvidedId: result.id,
      longAddress: result.address?.label,
    };
  }

  async getDirectionsBetweenPlaces(
    origin: GeographicPlace,
    destination: GeographicPlace
  ): Promise<Route> {
    return getDirectionsBetweenPlacesUsingGoogle(origin, destination, false);
  }

  getMapIframe(): React.MemoExoticComponent<(props: EmbeddedMapProps) => JSX.Element> {
    return AlfredMapIframe;
  }

  readonly supportedModesOfTransport: TransportationMode[] = [
    TransportationMode.BIKING,
    TransportationMode.DRIVING,
    TransportationMode.PUBLIC_TRANSPORTATION,
    TransportationMode.WALKING,
  ];
}
