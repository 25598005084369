import classNames from "classnames";
import type React from "react";

interface ButtonPanelProps {
  children: React.ReactNode;
  className?: string;
  span?: boolean;
}

export const ButtonPanel = (props: ButtonPanelProps) => {
  if (props.span) {
    return <span className={classNames("button-panel", props.className)}>{props.children}</span>;
  } else {
    return <div className={classNames("button-panel", props.className)}>{props.children}</div>;
  }
};
