import { Text } from "@mantine/core";
import { initGoogleApi } from "apis/external/GooglePlacesApi";

import { geocodeAddressFromHERE } from "apis/external/HEREApi";
import { searchPlacesViaPhoton } from "apis/external/osm/PhotonApi";
import {
  TransportationMode,
  type Coordinates,
  type GeographicPlace,
  type PartialGeographicPlace,
  type Route,
} from "types/geo";
import type { Nullable, Optional } from "types/utils";
import { GeoMaster, GeoMasterName } from "../geomaster";
import { AlfredMapIframe } from "../maps/AlfredMapIframe";
import type { EmbeddedMapProps } from "../maps/types";
import {
  getDirectionsBetweenPlacesUsingGoogle,
  hereCoordsToStandardCoords,
  reverseGeocodeToOSM,
} from "./utils";

// DESIGN:
// Relies on Targomo for autocomplete, HERE for inferring places, Google for directions
// Currently the best mix of cost effectiveness and user experience

/******************************************************
███╗   ███╗██╗ ██████╗██╗  ██╗ █████╗ ███████╗██╗     
████╗ ████║██║██╔════╝██║  ██║██╔══██╗██╔════╝██║     
██╔████╔██║██║██║     ███████║███████║█████╗  ██║     
██║╚██╔╝██║██║██║     ██╔══██║██╔══██║██╔══╝  ██║     
██║ ╚═╝ ██║██║╚██████╗██║  ██║██║  ██║███████╗███████╗
╚═╝     ╚═╝╚═╝ ╚═════╝╚═╝  ╚═╝╚═╝  ╚═╝╚══════╝╚══════╝
                                                      
******************************************************/

export class Michael extends GeoMaster {
  static geoMasterName = GeoMasterName.MICHAEL;

  async init(): Promise<void> {
    await initGoogleApi();
  }

  async getAutocompleteFromQuery(
    query: string,
    reference?: Optional<Coordinates>
  ): Promise<PartialGeographicPlace[]> {
    const predictions = await searchPlacesViaPhoton(query, reference);
    return predictions.map((x) => ({
      geoMasterName: Michael.geoMasterName,
      name: x.name,
      coords: x.coords,
      subtext: [x.address.street, x.address.region, x.address.country]
        .filter((x) => !!x)
        .join(" ,"),
    }));
  }

  async getPlaceForAutocompleteUsingQuery(
    query: string,
    reference: Optional<Coordinates>
  ): Promise<Nullable<GeographicPlace>> {
    const place = await this.getPlaceFromOpenQuery(query, reference);
    if (place) {
      const OSMLocation = await reverseGeocodeToOSM(place.coords);
      return {
        geoMasterName: Michael.geoMasterName,
        timestampInMillis: Date.now(),
        name: OSMLocation.name ?? "Unnamed",
        coords: place.coords,
        address: OSMLocation.address,
      };
    } else {
      return null;
    }
  }
  async getPlaceFromSpecificCoordinates(coordinates: Coordinates): Promise<GeographicPlace> {
    const OSMLocation = await reverseGeocodeToOSM(coordinates);
    return {
      geoMasterName: Michael.geoMasterName,
      timestampInMillis: Date.now(),
      name: OSMLocation.name ?? "Unnamed",
      coords: OSMLocation.coords,
      address: OSMLocation.address,
    };
  }

  async getPlaceForAutocompleteUsingPartial(
    partialPlace: PartialGeographicPlace
  ): Promise<GeographicPlace> {
    if (partialPlace.geoMasterName !== Michael.geoMasterName) {
      throw new Error("Michael received invalid data!");
    }

    if (!partialPlace.coords) {
      throw new Error("We couldn't get the coordinates of this place. Sorry!");
    }

    const OSMLocation = await reverseGeocodeToOSM(partialPlace.coords);
    return {
      geoMasterName: Michael.geoMasterName,
      timestampInMillis: Date.now(),
      name: OSMLocation.name ?? "Unnamed",
      coords: partialPlace.coords,
      address: OSMLocation.address,
    };
  }

  getAttestationForAutocomplete(): React.ReactElement {
    return <Text>Powered by OSM</Text>;
  }

  async getPlaceFromOpenQuery(
    query: string,
    reference: Optional<Coordinates>
  ): Promise<Nullable<GeographicPlace>> {
    const result = await geocodeAddressFromHERE(query, reference);
    console.log(result);
    if (!result?.position) {
      throw new Error("We couldn't get the coordinates of this place. Sorry!");
    }

    return {
      geoMasterName: Michael.geoMasterName,
      timestampInMillis: Date.now(),
      name: result.title ?? "Unnamed",
      coords: hereCoordsToStandardCoords(result.position),
      longAddress: result.address?.label,
    };
  }

  async getDirectionsBetweenPlaces(
    origin: GeographicPlace,
    destination: GeographicPlace
  ): Promise<Route> {
    return getDirectionsBetweenPlacesUsingGoogle(origin, destination, false);
  }

  getMapIframe(): React.MemoExoticComponent<(props: EmbeddedMapProps) => JSX.Element> {
    return AlfredMapIframe;
  }

  readonly supportedModesOfTransport: TransportationMode[] = [
    TransportationMode.BIKING,
    TransportationMode.DRIVING,
    TransportationMode.PUBLIC_TRANSPORTATION,
    TransportationMode.WALKING,
  ];
}
