import {
  mapGoogleModeOfTransport,
  mapGooglePublicTransportationMode,
  requestDirectionsBetweenPlacesFromGoogle,
} from "apis/external/GooglePlacesApi";
import haversine from "haversine-distance";

import type { HERECoordinates } from "apis/external/HEREApi";
import { reverseGeocodeFromNominatim } from "apis/external/osm/NominatimApi";
import { reverseGeocodeFromPhoton } from "apis/external/osm/PhotonApi";
import type { OSMResult } from "apis/external/osm/types";
import type { Coordinates, GeographicPlace, Route, RouteDirections, RouteLeg } from "types/geo";
import type { Optional } from "types/utils";
import { SHORT_TIMEOUT, timedPromise } from "utils/promises";

function recursivelyConstructSteps(steps: google.maps.DirectionsStep[], accumulator: RouteLeg[]) {
  steps.forEach((step) => {
    if (!step.steps?.length) {
      accumulator.push({
        distanceInMeters: step.distance?.value,
        durationInMillis: step.duration?.value ? step.duration.value * 1000 : undefined,
        modeOfTransport: mapGoogleModeOfTransport(step.travel_mode),
        transitDetails: step.transit && {
          line: {
            icon: step.transit.line.icon,
            shortName: step.transit.line.short_name,
            color: step.transit.line.color,
            vehicle: mapGooglePublicTransportationMode(step.transit.line.vehicle.type),
          },
          startStation: {
            name: step.transit.departure_stop.name,
          },
          endStation: {
            name: step.transit.arrival_stop.name,
          },
          numberOfStops: step.transit.num_stops,
        },
      });
    } else {
      recursivelyConstructSteps(step.steps ?? [], accumulator);
    }
  });
}

/**
 * Uses a combination of OSM-based APIs to reverse geocode a location.
 * Internally uses timedPromise, so it can timeout
 * @param coords
 */
export const reverseGeocodeToOSM = async (coords: Coordinates): Promise<OSMResult> => {
  const responses = await Promise.allSettled([
    timedPromise(reverseGeocodeFromNominatim(coords), SHORT_TIMEOUT),
    timedPromise(reverseGeocodeFromPhoton(coords), SHORT_TIMEOUT),
  ]);

  //Processing to be able to pick the best OSM suggestion
  const sortedSuggestions = responses
    .filter((pr) => pr.status === "fulfilled")
    .map((pr) => (pr as PromiseFulfilledResult<OSMResult>).value)
    .sort((a, b) => haversine(coords, a.coords) - haversine(coords, b.coords));

  if (sortedSuggestions.length === 0) {
    throw new Error("We couldn't get the coordinates of this place. Sorry!");
  }
  return sortedSuggestions[0];
};

export async function getDirectionsBetweenPlacesUsingGoogle(
  origin: GeographicPlace,
  destination: GeographicPlace,
  useDestinationId: boolean
): Promise<Route> {
  const route: Route = { origin, destination, route: null };
  const receivedRoutes = await requestDirectionsBetweenPlacesFromGoogle(
    origin.coords,
    destination.coords,
    useDestinationId ? destination.geoMasterProvidedId : undefined
  );
  const receivedRoute = receivedRoutes?.routes.at(0);
  if (!receivedRoute) return route;

  const constructedRoute: RouteDirections = {
    legs: [],
    copyright: receivedRoute.copyrights,
    warnings: receivedRoute.warnings,
  };

  receivedRoute.legs.forEach((leg) => {
    recursivelyConstructSteps(leg.steps, constructedRoute.legs);
  });

  route.route = constructedRoute;
  return route;
}

export function hereCoordsToStandardCoords<T extends Optional<HERECoordinates>>(
  coords: T
): T extends null | undefined ? undefined : Coordinates {
  if (!coords) return coords as any;
  return { latitude: coords.lat, longitude: coords.lng } as any;
}
