import { AxiosClientWithCache } from "apis/cachedAxiosClient";
import type { Coordinates } from "types/geo";
import type { OSMResult } from "./types";

//https://nominatim.org/release-docs/develop/api/Output/#json
//This is just the subset of fields we care about
type OSMAddressDetails = {
  continent?: string;
  country?: string;
  country_code?: string;
  region?: string;
  state?: string;
  state_district?: string;
  county?: string;
  municipality?: string;
  city?: string;
  town?: string;
  village?: string;
  city_district?: string;
  district?: string;
  borough?: string;
  suburb?: string;
  neighbourhood?: string;
  allotments?: string;
  road?: string;
  house_number?: string;
  house_name?: string;
  postcode?: string;
};

interface NominatimPlace {
  osm_id?: number;
  lat: string;
  lon: string;
  display_name: string;
  error?: string;
  address?: OSMAddressDetails;
}

export const reverseGeocodeFromNominatim = async (coords: Coordinates): Promise<OSMResult> => {
  let queryUrl = "https://nominatim.openstreetmap.org/reverse?";
  queryUrl += `lat=${coords.latitude}&lon=${coords.longitude}&`;
  queryUrl += `format=json`;

  const response = (await AxiosClientWithCache.get(queryUrl)).data as NominatimPlace;
  if (!response.error) {
    const osmResult: OSMResult = {
      name: response.display_name,
      coords: {
        latitude: parseFloat(response.lat),
        longitude: parseFloat(response.lon),
      },
      address: {
        country: response.address?.country,
        region: response.address?.state,
        city: response.address?.city ?? response.address?.town ?? response.address?.village,
        street: response.address?.road,
        number: response.address?.house_number,
        postcode: response.address?.postcode,
      },
      source: "nominatim",
    };
    return osmResult;
  } else {
    throw new Error("Error Processing final place information: " + response.error);
  }
};
