import { Modal } from "@mantine/core";
import { useState } from "react";

export const KofiButton = () => {
  const [opened, setOpened] = useState(false);
  const openModal = () => setOpened(true);
  const closeModal = () => setOpened(false);

  return (
    <>
      <div onClick={openModal} style={{ cursor: "pointer" }}>
        <img
          height="36"
          style={{ border: "0px", height: "36px" }}
          src="https://storage.ko-fi.com/cdn/kofi6.png?v=6"
          alt="Buy Me a Coffee at ko-fi.com"
        />
      </div>

      <Modal
        opened={opened}
        onClose={closeModal}
        title="Support us on Ko-fi"
        size="md"
        withCloseButton
      >
        <iframe
          id="kofiframe"
          height="712"
          title="theretowhere"
          src="https://ko-fi.com/theretowhere/?hidefeed=true&widget=true&embed=true&preview=true"
          style={{
            border: "none",
            width: "100%",
            padding: "4px",
            background: "#f9f9f9",
          }}
        />
      </Modal>
    </>
  );
};
