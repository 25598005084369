import { ActionIcon, Badge, Group, Text, Title, Tooltip } from "@mantine/core";
import { ButtonPanel } from "common_ui/ButtonPanel";
import { TouchFriendlyTooltip } from "common_ui/TouchFriendlyTooltip";
import type { HeatmapConfigurationPageNavigationArgs } from "pages/heatmap_configuration/types";
import { memo, useCallback } from "react";
import { FiHelpCircle, FiMap, FiTrash } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import type { StoredHeatmap } from "types/heatmap_storage/storedHeatmap";
import { epochToDateString } from "utils/datetime";

const HeatmapListElementImpl = (props: {
  heatmap: StoredHeatmap;
  onDelete: (id: string) => void;
  isBrowserExtensionDefault: boolean;
}) => {
  const navigate = useNavigate();

  const navigateToPage = useCallback(() => {
    const locationState: HeatmapConfigurationPageNavigationArgs = { heatmapId: props.heatmap.id };
    navigate("/heatmap/view", { state: locationState });
  }, [navigate, props.heatmap]);

  const deleteHeatmap = useCallback(() => {
    props.onDelete(props.heatmap.id);
  }, [props]);

  const goToBrowserExtensionPage = useCallback(() => {
    navigate("/we-got-an-extension-babyyyy");
  }, [navigate]);

  return (
    <div className="p-2">
      <Group>
        <Title order={3}>{props.heatmap.name ?? "Untitled Heatmap"}</Title>
        {props.isBrowserExtensionDefault && (
          <>
            <TouchFriendlyTooltip
              label="This is the heatmap our browser extension will use. For now, it's always the latest heatmap."
              multiline
            >
              <Badge variant="filled" color="teal">
                Browser extension default
              </Badge>
            </TouchFriendlyTooltip>
            <ActionIcon variant="transparent" onClick={goToBrowserExtensionPage}>
              <FiHelpCircle />
            </ActionIcon>
          </>
        )}
      </Group>
      {props.heatmap.creationTimestamp && (
        <Text>Created {epochToDateString(props.heatmap.creationTimestamp)}</Text>
      )}
      <Text>{Object.keys(props.heatmap.heatmap.groups).length} criteria</Text>
      <ButtonPanel>
        <Tooltip label={"Delete"} withArrow>
          <ActionIcon onClick={deleteHeatmap} color="red" variant="light">
            <FiTrash />
          </ActionIcon>
        </Tooltip>
        <Tooltip label={"View"} withArrow>
          <ActionIcon onClick={navigateToPage} variant="light" color="blue">
            <FiMap />
          </ActionIcon>
        </Tooltip>
      </ButtonPanel>
    </div>
  );
};

export const HeatmapListElement = memo(HeatmapListElementImpl);
