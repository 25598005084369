import { Anchor, Group, Text } from "@mantine/core";
import { FiInfo } from "react-icons/fi";
import { getEnvVariable } from "utils/env";

export const WorkInProgressBanner = () => {
  return (
    <Group className="w-100 px-2 justify-center" bg="#cbe4f0" c="dark" align="center">
      <Text span>
        <FiInfo /> This project is is still in active development! Reach out over{" "}
        <Anchor href={getEnvVariable("REACT_APP_SUPPORT_TWITTER")} target="_blank">
          Twitter
        </Anchor>
        ,{" "}
        <Anchor href={getEnvVariable("REACT_APP_SUPPORT_BLUESKY")} target="_blank">
          BlueSky
        </Anchor>{" "}
        or{" "}
        <Anchor href={`mailto:${getEnvVariable("REACT_APP_SUPPORT_EMAIL")}`} target="_blank">
          Email
        </Anchor>
        !
      </Text>
    </Group>
  );
};
