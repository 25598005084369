import { Box, Button } from "@mantine/core";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useLocallyStoredHeatmapForBrowserExtension } from "storage/localHeatmapStorage";
import { isInChrome } from "utils/browser";
import { assertDefined } from "utils/misc";
import { safeFloat, safeInt } from "utils/numbers";
import { EmbeddedHeatmapInner } from "./EmbeddedHeatmapInner";
import "./EmbeddedHeatmap.css";

// See the browser extension README to understand the design of this
const EmbeddedHeatmapPageImpl = () => {
  const [hasUnpartitionedStoragePerms, setHasUnpartitionedStoragePerms] = useState(false);
  const [encounteredError, setEncounteredError] = useState(false);
  const idbFactoryRef = useRef<IDBFactory>();

  const getPermissions = useCallback(async () => {
    try {
      // @ts-ignore
      const handler: Undefined<{ indexedDB: IDBFactory }> = await document.requestStorageAccess({
        indexedDB: true,
      });

      if (handler?.indexedDB) {
        setHasUnpartitionedStoragePerms(true);
        idbFactoryRef.current = handler.indexedDB;
      }
    } catch {
      setEncounteredError(true);
    }
  }, []);

  if (!isInChrome()) {
    return (
      <Box className="embedded-heatmap-message" bg="red">
        This page is only supported on Chrome 😭
      </Box>
    );
  }

  if (encounteredError) {
    return (
      <Box className="embedded-heatmap-message" bg="red">
        Something broke - you likely haven't visited theretowhere.com yet.
      </Box>
    );
  }

  if (!hasUnpartitionedStoragePerms) {
    return (
      <>
        <Button
          onClick={getPermissions}
          className="embedded-heatmap-permissions-button"
          color="red"
        >
          Display Theretowhere Heatmap
        </Button>
      </>
    );
  }

  return <EmbeddedHeatmap factory={assertDefined(idbFactoryRef.current)} />;
};

const EmbeddedHeatmap = (props: { factory: IDBFactory }) => {
  const location = useLocation();
  const hash = location.hash.replace("#", "").split("&");
  const zoom = safeInt(hash.at(0)) || 0;
  const lat = safeFloat(hash.at(1)) || 0;
  const long = safeFloat(hash.at(2)) || 0;

  const heatmap = useLocallyStoredHeatmapForBrowserExtension(props.factory);

  useEffect(() => {
    if (heatmap) {
      window.parent?.postMessage("THERETOWHERE_GROW_IFRAME", "*");
    }
  });

  if (!heatmap) {
    return (
      <Box className="embedded-heatmap-message" bg="red">
        You don't have a saved heatmap!
      </Box>
    );
  }

  return (
    <>
      <div className="embedded-heatmap-parent">
        <EmbeddedHeatmapInner zoom={zoom} long={long} lat={lat} heatmap={heatmap} />
      </div>
    </>
  );
};

export const EmbeddedHeatmapPage = memo(EmbeddedHeatmapPageImpl);
