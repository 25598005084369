import {
  ActionIcon,
  Box,
  Group,
  TextInput,
  Title,
  Tooltip,
  useMantineColorScheme,
} from "@mantine/core";
import { CompactColorInput } from "common_ui/CompactColorInput";
import { FiEdit, FiEye, FiEyeOff, FiTrash } from "react-icons/fi";
import { DEFAULT_GROUP_PIN_COLOR } from "./constants";
import { useToggleState } from "utils/hooks";
import { type ChangeEvent, type FocusEvent, type ReactNode, useCallback } from "react";
import { ButtonPanel } from "common_ui/ButtonPanel";

interface GroupHeaderProps {
  name: string;
  color?: string;
  hidden?: boolean;
  onColorChange?: (color: string) => void;
  onNameChange?: (name: string) => void;
  onToggleHide?: () => void;
  onDelete?: () => void;
  left?: ReactNode;
  right?: ReactNode;
}

export const GroupHeader = (props: GroupHeaderProps) => {
  const { colorScheme } = useMantineColorScheme();
  const { onNameChange } = props;
  const [inEditMode, toggleEditMode] = useToggleState(false);

  const onInputKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        toggleEditMode();
      }
    },
    [toggleEditMode]
  );

  const onInputKeyChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onNameChange?.(e.target.value);
    },
    [onNameChange]
  );

  const onFocus = useCallback((e: FocusEvent<HTMLInputElement, Element>) => {
    e.target.select();
  }, []);

  return (
    <Box bg={colorScheme === "dark" ? "grape.8" : "grape.1"} className="rounded-md p-2">
      <Group>
        {props.left}
        {inEditMode ? (
          <>
            <TextInput
              onChange={onInputKeyChange}
              value={props.name}
              placeholder="Enter a name for this criterion"
              required
              onBlur={toggleEditMode}
              onKeyDown={onInputKeyDown}
              autoFocus
              onFocus={onFocus}
            />
          </>
        ) : (
          <Title order={4}>{props.name}</Title>
        )}

        {!inEditMode && props.onNameChange && (
          <Tooltip label={"Rename this criteria"} withArrow>
            <ActionIcon onClick={toggleEditMode} variant="light">
              <FiEdit />
            </ActionIcon>
          </Tooltip>
        )}

        <div className="flex-1" />
        <ButtonPanel span>
          {props.onToggleHide && (
            <Tooltip label={"Hide map pins"} withArrow>
              <ActionIcon onClick={props.onToggleHide} variant="light">
                {props.hidden ? <FiEyeOff /> : <FiEye />}
              </ActionIcon>
            </Tooltip>
          )}

          {props.color && props.onColorChange && (
            <CompactColorInput
              color={props.color ?? DEFAULT_GROUP_PIN_COLOR}
              onChange={props.onColorChange}
              tooltip="Change map pin color"
            />
          )}

          {props.onDelete && (
            <Tooltip label={"Delete this criteria"} withArrow>
              <ActionIcon onClick={props.onDelete} color="red" variant="light">
                <FiTrash />
              </ActionIcon>
            </Tooltip>
          )}
        </ButtonPanel>
        {props.right}
      </Group>
    </Box>
  );
};
