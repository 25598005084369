import { memo, useEffect, useMemo, useRef } from "react";

import { Card, Text } from "@mantine/core";
import { Overlay, type Map } from "ol";
import type Feature from "ol/Feature";
import type { Polygon } from "ol/geom";
import type {
  Heatmap,
  HeatmapPartProperties,
  HeatmapTransportationMode,
} from "types/internal_apis/get_heatmap";
import type { Optional, Undefined } from "types/utils";
import { assertDefined } from "utils/misc";
import { truncateStringOpt } from "utils/strings";
import type { Coordinate } from "ol/coordinate";

interface HeatmapPartOverlayProps {
  heatmap: Heatmap;
  feature: Feature<Polygon>;
  map: Map;
}

interface SimplifiedBucket {
  groupName: string;
  placeName: Optional<string>;
  timeEstimateInS: Optional<number>;
  transportationMode: HeatmapTransportationMode;
}

const HeatmapPartOverlayImpl = (props: HeatmapPartOverlayProps) => {
  const { map, heatmap, feature } = props;
  const ref = useRef<HTMLDivElement>(null);

  const properties = useMemo(() => feature.getProperties() as HeatmapPartProperties, [feature]);

  const buckets = useMemo(() => {
    const simplifiedBuckets: SimplifiedBucket[] = [];
    for (const bucket of Object.values(properties.buckets_per_place_group)) {
      const placeId = bucket.place_id;
      const place = placeId != null ? assertDefined(heatmap.places[placeId]) : null;
      const group = assertDefined(heatmap.groups[bucket.group_id]);
      simplifiedBuckets.push({
        groupName: group.name,
        placeName: truncateStringOpt(place?.name, 30),
        timeEstimateInS: bucket.bucket_in_s,
        transportationMode: group.transportation_mode,
      });
    }
    return simplifiedBuckets;
  }, [heatmap, properties]);

  useEffect(() => {
    const overlay = new Overlay({
      element: ref.current ?? undefined,
    });
    const geometry = feature.getGeometry();

    //Get the rightmost coordinate
    let coordinate: Undefined<Coordinate> = undefined;
    const allCoordinates = assertDefined(geometry?.getCoordinates().at(0));
    for (const candidate of allCoordinates) {
      if (!coordinate || candidate[0] > coordinate[0]) {
        coordinate = candidate;
      }
    }

    overlay.setPosition(assertDefined(coordinate));
    map.addOverlay(overlay);
    return () => {
      overlay.setMap(null);
    };
  }, [feature, map]);

  // Wrapping this component in a div is important: the OpenLayers map is going to move
  // the reffed inner div, and without an outer div, the React virtual DOM will be messed up
  return (
    <div>
      <div ref={ref} className="heatmap-overlay">
        <Card shadow="sm" padding="sm" radius="md">
          <Text weight={"bold"}>Heatmap Part</Text>
          {buckets.map((bucket, index) => (
            <SimplifiedBucketComponent key={index} bucket={bucket} />
          ))}
        </Card>
      </div>
    </div>
  );
};

export const HeatmapPartOverlay = memo(HeatmapPartOverlayImpl);

const SimplifiedBucketComponent = (props: { bucket: SimplifiedBucket }) => {
  const bucket = props.bucket;

  if (!bucket.placeName || !bucket.timeEstimateInS) {
    return <Text fz="sm">{`Not close enough to ${bucket.groupName}`}</Text>;
  }

  return (
    <Text fz="sm">
      {`~${Math.round(bucket.timeEstimateInS / 60)} mins - ${bucket.groupName}${
        bucket.placeName ? ` (${bucket.placeName})` : ""
      }`}
    </Text>
  );
};
