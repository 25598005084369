import type { FeatureCollection, Polygon } from "geojson";
import type { Brand, Optional } from "../utils";

// This should match the value in the server
export const CURRENT_HEATMAP_SCHEMA_VERSION = 1;

export type GroupID = Brand<string, "groupId">;
export type PlaceID = Brand<string, "placeId">;
export type PartID = Brand<string, "partId">;

export type Point = [number, number];

export enum HeatmapTransportationMode {
  WALKING = "walk",
  BIKING = "bike",
  DRIVING = "car",
  PUBLIC_TRANSPORTATION = "public_transportation",
}

export interface HeatmapRequest {
  groups: Array<{
    id: string;
    max_bucket_in_s: number;
    transportation_mode: HeatmapTransportationMode;
    name: string;
  }>;
  places: Array<{
    name: string;
    id: string;
    group_id: string;
    points: Array<Point>;
  }>;
}

export interface HeatmapPlaceGroup {
  id: GroupID;
  name: string;
  max_bucket_in_s: number;
  transportation_mode: HeatmapTransportationMode;
}

export interface HeatmapPlace {
  id: PlaceID;
  name: string;
  points: Point[];
  group_id: GroupID;
}

export interface HeatmapPartBucket {
  group_id: GroupID;
  // a non existent place_id and bucket_in_s means
  // this part wasn't close enough to any place in this group
  place_id: Optional<PlaceID>;
  bucket_in_s: Optional<number>;
}

export interface HeatmapPartProperties {
  buckets_per_place_group: Record<GroupID, HeatmapPartBucket>;
  id: PartID;
}

export interface Heatmap {
  schema_version: number;
  feature_collection: FeatureCollection<Polygon, HeatmapPartProperties>;
  places: Record<PlaceID, HeatmapPlace>;
  groups: Record<GroupID, HeatmapPlaceGroup>;
}
