import {
  ActionIcon,
  Button,
  Divider,
  Group,
  NumberInput,
  Select,
  Stack,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import { DEFAULT_GROUP_PIN_COLOR } from "common_ui/heatmap_specific/constants";
import { GroupHeader } from "common_ui/heatmap_specific/GroupHeader";
import PlacesAutocompleteText from "common_ui/PlaceAutocompleteTextInput";
import { memo, useCallback } from "react";
import { FiChevronDown, FiChevronUp, FiPlusCircle, FiSearch, FiTrash } from "react-icons/fi";
import type { GeographicPlace } from "types/geo";
import type { HeatmapTransportationMode } from "types/internal_apis/get_heatmap";
import { arrayRemoveFirstStrict, arrayReplaceFirstStrict } from "utils/arrays";
import {
  transportationModeDropdownOptions,
  transportationModeToString,
} from "utils/heatmap/transportationModes";
import { useBooleanState, useToggleState } from "utils/hooks";
import { v4 as uuid } from "uuid";
import { HeatmapPointChooserOpenSearchModal } from "./HeatmapPointChooserOpenSearchModal";
import type { PartialGroup, PartialPlace } from "./types";
import { ButtonPanel } from "common_ui/ButtonPanel";

interface GroupListElementProps {
  group: PartialGroup;
  onDeleteDelete: (group: PartialGroup) => void;
  onGroupEdit: (group: PartialGroup) => void;
}

export const GroupListElement = memo((props: GroupListElementProps) => {
  const { group, onGroupEdit: propsOnGroupEdit, onDeleteDelete: propsOnGroupDelete } = props;

  const [isSearchModalOpen, openOpenSearchModal, closeOpenSearchModal] = useBooleanState(false);
  const [collapsed, toggleCollapsed] = useToggleState(false);

  const onGroupEdit = useCallback(
    (newGroup: PartialGroup) => {
      propsOnGroupEdit(newGroup);
    },
    [propsOnGroupEdit]
  );

  const onDeleteGroup = useCallback(() => {
    propsOnGroupDelete(group);
  }, [group, propsOnGroupDelete]);

  const onChangeDuration = useCallback(
    (duration: number | "") => {
      const coalescedNumber = duration || 0;
      onGroupEdit({ ...group, max_bucket_in_s: coalescedNumber * 60 });
    },
    [group, onGroupEdit]
  );

  const onChangeMode = useCallback(
    (mode: string) => {
      onGroupEdit({ ...group, mode: mode as HeatmapTransportationMode });
    },
    [group, onGroupEdit]
  );

  const onAddLocation = useCallback(() => {
    onGroupEdit({
      ...group,
      places: [{ id: uuid() }, ...group.places],
    });
  }, [group, onGroupEdit]);

  const onAddLocations = useCallback(
    (places: PartialPlace[]) => {
      onGroupEdit({
        ...group,
        places: [...places, ...group.places],
      });
    },
    [group, onGroupEdit]
  );

  const onChangeLocation = useCallback(
    (place: PartialPlace) => {
      onGroupEdit({
        ...group,
        places: arrayReplaceFirstStrict(group.places, (x) => x.id === place.id, place),
      });
    },
    [group, onGroupEdit]
  );

  const onChangeGroupColor = useCallback(
    (color: string) => {
      onGroupEdit({ ...group, color });
    },
    [group, onGroupEdit]
  );

  const onChangeGroupName = useCallback(
    (name: string) => {
      onGroupEdit({ ...group, name });
    },
    [group, onGroupEdit]
  );

  const onToggleVisibility = useCallback(() => {
    onGroupEdit({ ...group, hidePoints: !group.hidePoints });
  }, [group, onGroupEdit]);

  const onDeleteLocation = useCallback(
    (placeId: string) => {
      onGroupEdit({
        ...group,
        places: arrayRemoveFirstStrict(group.places, (x) => x.id === placeId),
      });
    },
    [group, onGroupEdit]
  );

  return (
    <div className="col pt-4 gap-2">
      <HeatmapPointChooserOpenSearchModal
        open={isSearchModalOpen}
        onModalClose={closeOpenSearchModal}
        onPlacesChosen={onAddLocations}
      />

      <GroupHeader
        name={group.name}
        color={group.color ?? DEFAULT_GROUP_PIN_COLOR}
        onColorChange={onChangeGroupColor}
        onDelete={onDeleteGroup}
        onToggleHide={onToggleVisibility}
        onNameChange={onChangeGroupName}
        hidden={!!group.hidePoints}
        left={
          <ActionIcon onClick={toggleCollapsed}>
            {collapsed ? <FiChevronUp /> : <FiChevronDown />}
          </ActionIcon>
        }
      />

      <div className="col ml-2 gap-2">
        <Text c="dimmed">
          {`You want to be at most ${
            (group.max_bucket_in_s ?? 0) / 60
          } minutes by ${transportationModeToString(group.mode)} from any of these ${
            group.places.length
          } places.`}
        </Text>

        {!collapsed && (
          <>
            <div className="row flex-wrap w-100 gap-3">
              <div className="min-w-fit flex-1">
                <NumberInput
                  label="Max time away from any of these places"
                  description="Note that this is in minutes"
                  onChange={onChangeDuration}
                  value={(group.max_bucket_in_s ?? 0) / 60}
                />
              </div>
              <div className="min-w-fit flex-1">
                <Select
                  label="Mode of Transportation"
                  description="How do you plan to get there?"
                  withinPortal
                  value={group.mode}
                  onChange={onChangeMode}
                  data={transportationModeDropdownOptions}
                />
              </div>
            </div>

            <Stack pt={8}>
              <Group>
                <Title order={4}>Places</Title>
                <ButtonPanel>
                  <Tooltip label={"Add a specific destination to this criteria"} withArrow>
                    <Button onClick={onAddLocation} leftIcon={<FiPlusCircle />}>
                      Add Specific place
                    </Button>
                  </Tooltip>
                  <Tooltip
                    label={"Search destinations around you to add to this criteria"}
                    withArrow
                  >
                    <Button onClick={openOpenSearchModal} leftIcon={<FiSearch />}>
                      Search nearby places
                    </Button>
                  </Tooltip>
                </ButtonPanel>
              </Group>

              <div className="col gap-3">
                {group.places.map((place) => (
                  <GroupPlaceListElement
                    key={place.id}
                    place={place}
                    onPlaceDelete={onDeleteLocation}
                    onPlaceEdit={onChangeLocation}
                  />
                ))}
              </div>
            </Stack>
          </>
        )}
      </div>
      <Divider />
    </div>
  );
});

interface GroupPlaceListElementProps {
  place: PartialPlace;
  onPlaceEdit: (place: PartialPlace) => void;
  onPlaceDelete: (placeId: string) => void;
}

const GroupPlaceListElement = memo((props: GroupPlaceListElementProps) => {
  const { onPlaceEdit: propsOnPlaceEdit, onPlaceDelete: propsOnPlaceDelete } = props;

  const onPlaceEdit = useCallback(
    (place: GeographicPlace) => {
      propsOnPlaceEdit({ id: props.place.id, name: place.name, points: [place.coords] });
    },
    [props.place, propsOnPlaceEdit]
  );

  const onPlaceDelete = useCallback(() => {
    propsOnPlaceDelete(props.place.id);
  }, [props.place, propsOnPlaceDelete]);

  return (
    <div>
      <Group spacing={8}>
        <PlacesAutocompleteText
          onLocationChosen={onPlaceEdit}
          initialTextValue={props.place.name}
          autoFocus={!props.place.name}
        />
        <ButtonPanel span>
          <ActionIcon onClick={onPlaceDelete} color="red" variant="light">
            <FiTrash />
          </ActionIcon>
        </ButtonPanel>
      </Group>
    </div>
  );
});
