import { AxiosClientWithCache } from "apis/cachedAxiosClient";
import type { Coordinates } from "types/geo";
import type { Nullable } from "types/utils";
import { getEnvVariable } from "utils/env";
import { safeFloat } from "utils/numbers";

// TODO: consider moving this to my backend so Firefox doesn't block it.
// Chrome doesn't block it though, so it's not that bad.

//https://ipinfo.io/developers
type IpInfoResponse = Partial<{
  ip: string;
  city: string;
  region: string;
  country: string;
  loc: string; // Latitude,Longitude as a string
  org: string;
  postal: string;
  timezone: string;
}>;

export const getLocationFromIPAddress = async (): Promise<Nullable<Coordinates>> => {
  try {
    let queryUrl = `https://ipinfo.io/?token=${getEnvVariable("REACT_APP_IPINFO_KEY")}`;
    const response = (await AxiosClientWithCache.get(queryUrl)).data as IpInfoResponse;
    const coords = response.loc?.split(",").map((x) => x.trim());
    if (!coords) return null;
    const lat = safeFloat(coords[0]);
    const long = safeFloat(coords[1]);
    if (lat == null || long == null) return null;
    return {
      latitude: lat,
      longitude: long,
    };
  } catch {
    console.warn("Couldn't get user location information from ipinfo"); //TODO: should use sentry or something
    return null;
  }
};
