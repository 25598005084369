import axios, { type AxiosInstance, type AxiosRequestConfig, type AxiosResponse } from "axios";

export class AxiosClientWithCache {
  private static axios: AxiosInstance = axios.create();
  private static cache: Map<string, { data: AxiosResponse; timestamp: number }> = new Map();

  static async get<T = any>(
    url: string,
    config: AxiosRequestConfig = {},
    ttl?: number
  ): Promise<AxiosResponse<T>> {
    const cachedResponse = AxiosClientWithCache.cache.get(url);
    const now = Date.now();

    if (cachedResponse) {
      const hasExpired = !!ttl && now - cachedResponse.timestamp > ttl;
      if (!hasExpired) return cachedResponse.data;
    }

    try {
      const response: AxiosResponse = await AxiosClientWithCache.axios.get<T>(url, config);
      AxiosClientWithCache.cache.set(url, { data: response, timestamp: now });
      return response;
    } catch (error) {
      throw error;
    }
  }
}
