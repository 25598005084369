import Dexie, { type EntityTable } from "dexie";
import { useLiveQuery } from "dexie-react-hooks";
import type { StoredHeatmap } from "types/heatmap_storage/storedHeatmap";
import type { Undefined } from "types/utils";

type HeatmapDatabase = Dexie & {
  heatmaps: EntityTable<StoredHeatmap, "id">; //id is the primary key
};

let _db: Undefined<HeatmapDatabase> = undefined;

const getDatabase = (factory?: IDBFactory) => {
  const options = factory ? { indexedDB: factory } : undefined;
  if (!_db) {
    const db = new Dexie("theretowhere_stored_heatmaps", options) as HeatmapDatabase;
    db.version(1).stores({
      // We only need to index by ID and creation time.
      // First index defined is assumed to be the primary index
      heatmaps: "id, creationTimestamp",
    });
    _db = db;
  }

  return _db;
};

export const getLocallyStoredHeatmapById = async (id: string) => {
  const db = getDatabase();
  return await db.heatmaps.where("id").equals(id).first();
};

export const deleteLocallyStoredHeatmap = async (id: string) => {
  const db = getDatabase();
  await db.heatmaps.where("id").equals(id).delete();
};

export const saveHeatmapLocally = async (heatmap: StoredHeatmap) => {
  const db = getDatabase();
  await db.heatmaps.put(heatmap);
};

export const useLocallyStoredHeatmaps = () => {
  return useLiveQuery(() =>
    getDatabase().heatmaps.orderBy("creationTimestamp").reverse().toArray()
  );
};

export const useLocallyStoredHeatmapForBrowserExtension = (factory?: IDBFactory) => {
  return useLiveQuery(() =>
    getDatabase(factory).heatmaps.orderBy("creationTimestamp").reverse().first()
  );
};
