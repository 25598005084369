import { Text } from "@mantine/core";
import {
  initGoogleApi,
  requestAutocompleteFromGoogle,
  requestCompletePlaceDataFromGoogleFromId,
  requestCompletePlaceDataFromGoogleFromQuery,
} from "apis/external/GooglePlacesApi";

import {
  TransportationMode,
  type Coordinates,
  type GeographicPlace,
  type PartialGeographicPlace,
  type Route,
} from "types/geo";
import type { Nullable, Optional } from "types/utils";
import { GeoMaster, GeoMasterName } from "../geomaster";
import { AlfredMapIframe } from "../maps/AlfredMapIframe";
import type { EmbeddedMapProps } from "../maps/types";
import { getDirectionsBetweenPlacesUsingGoogle, reverseGeocodeToOSM } from "./utils";

// DESIGN:
// Relies on Google Places for Autocomplete, Google Places for inferring places, Google for directions
// Best for user experience but obscenely expensive.
// PRO FEATURE: consider allowing users to use this if they pay

/******************************************************
   ___      _        __                      _   
  /   \    | |      / _|    _ _    ___    __| |  
  | - |    | |     |  _|   | '_|  / -_)  / _` |  
  |_|_|   _|_|_   _|_|_   _|_|_   \___|  \__,_|  
_|"""""|_|"""""|_|"""""|_|"""""|_|"""""|_|"""""| 
"`-0-0-'"`-0-0-'"`-0-0-'"`-0-0-'"`-0-0-'"`-0-0-' 
******************************************************/

export class Alfred extends GeoMaster {
  static geoMasterName = GeoMasterName.ALFRED;

  async init(): Promise<void> {
    await initGoogleApi();
  }

  async getAutocompleteFromQuery(
    query: string,
    reference?: Optional<Coordinates>
  ): Promise<PartialGeographicPlace[]> {
    const predictions = await requestAutocompleteFromGoogle(query, reference);
    return predictions.map((x) => ({
      geoMasterName: Alfred.geoMasterName,
      geoMasterProvidedId: x.place_id,
      name: x.structured_formatting.main_text,
      distanceToUsersInMeters: x.distance_meters,
      subtext: x.structured_formatting.secondary_text,
    }));
  }

  async getPlaceFromSpecificCoordinates(coordinates: Coordinates): Promise<GeographicPlace> {
    const OSMLocation = await reverseGeocodeToOSM(coordinates);
    return {
      geoMasterName: Alfred.geoMasterName,
      timestampInMillis: Date.now(),
      name: OSMLocation.name ?? "Unnamed",
      coords: OSMLocation.coords,
      address: OSMLocation.address,
    };
  }

  async getPlaceForAutocompleteUsingQuery(
    query: string,
    reference: Optional<Coordinates>
  ): Promise<Nullable<GeographicPlace>> {
    const place = await this.getPlaceFromOpenQuery(query, reference);
    if (place) {
      const OSMLocation = await reverseGeocodeToOSM(place.coords);
      return {
        geoMasterName: Alfred.geoMasterName,
        timestampInMillis: Date.now(),
        name: OSMLocation.name ?? "Unnamed",
        coords: place.coords,
        geoMasterProvidedId: place.geoMasterProvidedId,
        address: OSMLocation.address,
      };
    } else {
      return null;
    }
  }

  async getPlaceForAutocompleteUsingPartial(
    partialPlace: PartialGeographicPlace
  ): Promise<GeographicPlace> {
    if (partialPlace.geoMasterName !== Alfred.geoMasterName || !partialPlace.geoMasterProvidedId) {
      throw new Error("Alfred received invalid data!");
    }

    //Getting the place's coordinates from Google
    const placeDetails = await requestCompletePlaceDataFromGoogleFromId(
      partialPlace.geoMasterProvidedId
    );
    const geo = placeDetails?.geometry?.location;
    if (!geo) {
      throw new Error("We couldn't get the coordinates of this place. Sorry!");
    }

    //Due to Google's restrictive TOS (we can't store their stuff on our servers),
    //its better to reverse geocode those coordinates using more free OSM solutions
    const googleCoordinates: Coordinates = { latitude: geo.lat(), longitude: geo.lng() };
    const OSMLocation = await reverseGeocodeToOSM(googleCoordinates);
    return {
      geoMasterName: Alfred.geoMasterName,
      timestampInMillis: Date.now(),
      name: OSMLocation.name ?? "Unnamed",
      coords: { latitude: geo.lat(), longitude: geo.lng() },
      geoMasterProvidedId: placeDetails.place_id,
      address: OSMLocation.address,
    };
  }

  getAttestationForAutocomplete(): React.ReactElement {
    return <Text>Powered by Google</Text>;
  }

  async getPlaceFromOpenQuery(
    query: string,
    reference: Optional<Coordinates>
  ): Promise<Nullable<GeographicPlace>> {
    const result = await requestCompletePlaceDataFromGoogleFromQuery(query, reference);
    const place = result?.at(0);
    if (!place) return null;
    const geo = place?.geometry?.location;
    if (!geo) {
      throw new Error("We couldn't get the coordinates of this place. Sorry!");
    }

    return {
      geoMasterName: Alfred.geoMasterName,
      timestampInMillis: Date.now(),
      name: place.name ?? "Unnamed",
      coords: { latitude: geo.lat(), longitude: geo.lng() },
      geoMasterProvidedId: place.place_id,
      longAddress: place.formatted_address,
    };
  }

  async getDirectionsBetweenPlaces(
    origin: GeographicPlace,
    destination: GeographicPlace
  ): Promise<Route> {
    return getDirectionsBetweenPlacesUsingGoogle(origin, destination, true);
  }

  getMapIframe(): React.MemoExoticComponent<(props: EmbeddedMapProps) => JSX.Element> {
    return AlfredMapIframe;
  }

  readonly supportedModesOfTransport: TransportationMode[] = [
    TransportationMode.BIKING,
    TransportationMode.DRIVING,
    TransportationMode.PUBLIC_TRANSPORTATION,
    TransportationMode.WALKING,
  ];
}
