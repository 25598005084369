import { AxiosClientWithCache } from "apis/cachedAxiosClient";
import type { Coordinates } from "types/geo";
import type { Optional } from "types/utils";
import { coalesceEmptyArray } from "utils/arrays";
import type { OSMResult } from "./types";

//https://photon.komoot.io/
interface PhotonReverseGeolocationResponse {
  message?: string;
  features?: Array<PhotonPlace>;
  type?: string;
}

interface PhotonPlace {
  geometry: { coordinates?: [number, number]; type: string };
  type: string;
  properties: PhotonReverseGeoPlaceProperties;
}

interface PhotonReverseGeoPlaceProperties {
  osm_id?: number;
  country?: string;
  city?: string;
  countrycode?: string;
  postcode?: string;
  county?: string;
  type?: string;
  osm_type?: string;
  osm_key?: string;
  street?: string;
  district?: string;
  osm_value?: string;
  name?: string;
  state?: string;
  housenumber?: string;
}

function inferPlaceName(properties: PhotonReverseGeoPlaceProperties): string {
  if (properties.name) return properties.name;

  const parts: string[] = [];

  if (properties.housenumber) parts.push(properties.housenumber);
  if (properties.street) parts.push(properties.street);
  if (properties.city) parts.push(properties.city);
  if (properties.county && !properties.city) parts.push(properties.county);
  if (properties.state) parts.push(properties.state);
  if (properties.country) parts.push(properties.country);

  return parts.length > 0 ? parts.join(", ") : "Unnamed Place";
}

const photonPlaceToOsmPlace = (photonPlace: PhotonPlace): Optional<OSMResult> => {
  if (photonPlace.geometry.coordinates) {
    const properties = photonPlace.properties;
    const osmResult: OSMResult = {
      name: inferPlaceName(photonPlace.properties),
      coords: {
        latitude: photonPlace.geometry.coordinates[1],
        longitude: photonPlace.geometry.coordinates[0],
      },
      address: {
        country: properties.country,
        region: properties.state,
        city: properties.city,
        street: properties.street,
        number: properties.housenumber,
        postcode: properties.postcode,
      },
      source: "photon",
    };
    return osmResult;
  }
  return null;
};

export const reverseGeocodeFromPhoton = async (coords: Coordinates): Promise<OSMResult> => {
  let queryUrl = "https://photon.komoot.io/reverse?";
  queryUrl += `lat=${coords.latitude}&lon=${coords.longitude}`;

  const response = (await AxiosClientWithCache.get(queryUrl))
    .data as PhotonReverseGeolocationResponse;
  if (!response.message && response.features) {
    const firstPlace = response.features.at(0);
    if (firstPlace) {
      const osmPlace = photonPlaceToOsmPlace(firstPlace);
      if (osmPlace) {
        return osmPlace;
      }
    }
  }

  throw new Error("Error Processing final place information! " + response.message);
};

export const searchPlacesViaPhoton = async (
  query: string,
  userCoords?: Optional<Coordinates>
): Promise<OSMResult[]> => {
  let queryUrl = `https://photon.komoot.io/api/?q=${query}&limit=5`;
  if (userCoords) queryUrl += `&lat=${userCoords.latitude}&lon=${userCoords.longitude}`;

  const response = (await AxiosClientWithCache.get(queryUrl))
    .data as PhotonReverseGeolocationResponse;
  return coalesceEmptyArray(
    response.features?.map((x) => photonPlaceToOsmPlace(x)).filter((x) => !!x)
  );
};
