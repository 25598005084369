import { Anchor } from "@mantine/core";

export const HackerNewsBadge = () => {
  // https://github.com/AnandChowdhary/hackerbadge
  return (
    <Anchor href="https://news.ycombinator.com/item?id=42975803" target="_blank">
      <img
        height="36"
        src="https://hackerbadge.vercel.app/api?id=42975803&type=orange"
        alt="Buy Me a Coffee at ko-fi.com"
      />
    </Anchor>
  );
};
