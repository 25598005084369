import { Alert, Button, Group, Space, Title } from "@mantine/core";
import { StandardEmptyState } from "common_ui/StandardEmptyState";
import { makePage, TAB_NAME_BASE } from "page_setup/makePage";
import { useCallback } from "react";
import { FiInfo, FiMap, FiPlusCircle } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { deleteLocallyStoredHeatmap, useLocallyStoredHeatmaps } from "storage/localHeatmapStorage";
import { HeatmapListElement } from "./HeatmapListElement";

const HeatmapListPageImpl = () => {
  const navigate = useNavigate();
  const goToHeatmapCreation = useCallback(() => navigate("/heatmap/create"), [navigate]);
  const heatmaps = useLocallyStoredHeatmaps();

  const onHeatmapDelete = useCallback((id: string) => {
    deleteLocallyStoredHeatmap(id);
  }, []);

  return (
    <div className="w100 h100 p-2 flex-col flex">
      <Group>
        <Title>Your Heatmaps</Title>
        <Button onClick={goToHeatmapCreation} leftIcon={<FiPlusCircle />}>
          Make New Heatmap
        </Button>
      </Group>

      <Space h="sm" />
      <div>
        <Alert
          icon={<FiInfo size="1rem" />}
          title="Stored locally - for now."
          color="blue"
          radius="md"
          style={{ border: "1px solid lightblue" }}
        >
          These are stored locally, but we're working on saving them in the cloud!
        </Alert>
      </div>

      <Space h="sm" />

      {heatmaps?.map((heatmap, index) => (
        <HeatmapListElement
          heatmap={heatmap}
          key={heatmap.id}
          onDelete={onHeatmapDelete}
          isBrowserExtensionDefault={index === 0}
        />
      ))}

      {!heatmaps?.length && (
        <div className="flex-1">
          <StandardEmptyState
            icon={<FiMap />}
            message="Heatmaps you create and save will show up here."
          />
        </div>
      )}
    </div>
  );
};

export const HeatmapListPage = makePage(HeatmapListPageImpl, TAB_NAME_BASE + "Heatmap List", {
  hideLocationUI: true,
});
