import { AxiosClientWithCache } from "apis/cachedAxiosClient";
import type { Coordinates } from "types/geo";
import type { Optional } from "types/utils";
import { getEnvVariable } from "utils/env";

// HERE API pricing: https://www.here.com/pricing
// HERE API reference: https://www.here.com/docs/bundle/geocoding-and-search-api-v7-api-reference/page/index.html#/paths
const HERE_API_KEY = getEnvVariable("REACT_APP_HERE_API_KEY");

export interface HERECoordinates {
  lat: number;
  lng: number;
}

interface HerePlace {
  id: string;
  title: string;
  address?: { label: string };
  distance?: number;
  position?: HERECoordinates;
}

interface HereAutocompleteResponse {
  items: HerePlace[];
}

interface HereGeocodeResponse {
  items: HerePlace[];
}

export async function requestAutocompleteResultsFromHERE(
  query: string,
  userCoords?: Optional<Coordinates>
): Promise<HerePlace[]> {
  const params = new URLSearchParams({
    q: query,
    apiKey: HERE_API_KEY,
    limit: "5",
  });

  if (userCoords) {
    params.append("at", `${userCoords.latitude},${userCoords.longitude}`);
  }
  const HERE_AUTOCOMPLETE_URL = "https://autocomplete.search.hereapi.com/v1/autosuggest";
  const response = await AxiosClientWithCache.get<HereAutocompleteResponse>(
    `${HERE_AUTOCOMPLETE_URL}?${params}`
  );
  return response.data.items.filter((x) => x.position) || [];
}

// Open search function
export async function geocodeAddressFromHERE(address: string, userCoords?: Optional<Coordinates>) {
  const params = new URLSearchParams({
    q: address,
    apiKey: HERE_API_KEY,
  });

  if (userCoords) {
    params.append("at", `${userCoords.latitude},${userCoords.longitude}`);
  }

  const HERE_GEOCODE_URL = "https://geocode.search.hereapi.com/v1/discover";
  const response = await AxiosClientWithCache.get<HereGeocodeResponse>(
    `${HERE_GEOCODE_URL}?${params}`
  );
  return response.data.items.at(0);
}
